import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
  state: {
    user: {},
    filter: {
      searchText: '',
      filterStatus: { key: 'all' },
      filterOnBoarding: { key: 'all' }
    }
  },
  mutations: {
    UPDATE_USER(state, datas) {
      state.user = datas;
    },
    UPDATE_AVATAR(state, datas) {
      state.user.avatar = datas;
    },
    UPDATE_NAME(state, datas) {
      state.user.name = datas;
    },
    UPDATE_PHONE(state, datas) {
      state.user.phone = datas;
    },
    UPDATE_FILTER_SEARCH(state, value) {
      state.filter.searchText = value
    },
    UPDATE_FILTER_STATUS(state, value) {
      state.filter.filterStatus = value
    },
    UPDATE_FILTER_OB(state, value) {
      state.filter.filterOnBoarding = value
    },
    CLEAR_FILTER_USER(state) {
      state.filter.searchText = ''
      state.filter.filterStatus = { key: 'all' }
      state.filter.filterOnBoarding = { key: 'all' }
    }
  },
  actions: {},
  getters: {
    user: () => JSON.parse(localStorage.getItem('USER')),
    filter: (state) => state.filter
  }
};
