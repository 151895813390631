import axios from "axios";
import { notification } from "ant-design-vue";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 360000
});

const getAuthToken = () => localStorage.getItem("token");

const authInterceptor = config => {
  config.headers["Authorization"] = "Bearer "+getAuthToken();
  return config;
};

httpClient.interceptors.request.use(authInterceptor);

// interceptor to catch errors
const errorInterceptor = error => {
  // all the error responses
  var message = JSON.stringify(error.response.data.message)
  var errorFormat = JSON.stringify(error.response.data.data);
  var temp = '';
  JSON.parse(errorFormat, function (key, value) {
    if(typeof value ==='string' || value instanceof String){
      temp = temp +'\n'+ value;
    }
  });
  switch (error.response.status) {
    case 400:
      notification["warning"]({
        message: "Bad Request",
        description: message
      });
      break;

    case 401: // authentication error, logout the user
      notification["warning"]({
        message: "Unauthorized",
        description: "Akun tidak ditemukan"
      });
      localStorage.clear();
      this.$router.push("/auth/login")
      break;

    case 422:
      notification["warning"]({
        message: "Opps.." + error.response.status,
        description: temp
      });
      break;

    default:
      notification["warning"]({
        message: "Opps.." + error.response.status,
        // message: "Opps.." + error.response.data.data,
        // description: message
        description: error.response.data.data,
      });
  }
  return Promise.reject(error);
};

// Interceptor for responses
const responseInterceptor = response => {
  switch (response.status) {
    case 200:
      // yay!
      break;
    // any other cases
    default:
    // default case
  }
  return response.data;
};

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default httpClient;
