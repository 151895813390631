import Vue from "vue";
import Vuex from "vuex";
import settings from "./settings";
import user from "./user";
import forum from "./forum";
import forgot from "./forgot";
import account from "./account";
import publik from "./public";
import library from "./library";
import notification from "./notification";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    settings,
    user,
    forum,
    forgot,
    account,
    publik,
    library,
    notification
  }
});
