import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
    state: {
        listAccount: [],
    },
    mutations: {
        UPDATE_DATA_ACCOUNT(state, data) {
            state.listAccount = data;
        },
    },
    actions: {},
    getters: {
        listAccount: state => state.listAccount
    }
};
