<template>
<!--  <a-input-search-->
<!--    placeholder="Cari No Order"-->
<!--    style="width: 140%"-->
<!--    @search="onSearch"-->
<!--  />-->
  <div></div>
</template>
<script>
export default {
  methods: {
    onSearch(){
      
    }
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
