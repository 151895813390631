<template>
  <div
    :class="{
      [$style.footer]: true,
      [$style.footerFullWidth]: settings.isContentNoMaxWidth
    }"
  >
    <div :class="$style.inner">
      <div class="row">
        <div class="col-md-8">
          <p>
            <strong>Dolarindo Connect Admin - Money Changer!</strong>
          </p>
          <p>&copy; 2020 Designed by Dolarindo</p>
        </div>
        <div class="col-md-4">
          <div :class="$style.logo">
            <img src="resources/logo-dolarindo.png" width="31" alt="Dolarindo" />
            <div :class="$style.logoName">Dolarindo</div>
            <div :class="$style.logoDescr">Connect</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState(['settings']),
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
