<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft">
    <div :class="$style.dropdown">
      <a-badge :count="getNotifCount">
        <a-avatar
          shape="square"
          :src="image"
          size="large"
          :class="$style.avatar"
          v-if="profile && image"
        />
        <a-avatar
          shape="square"
          src="resources/images/avatars/1.jpg"
          size="large"
          :class="$style.avatar"
          v-else
        />
      </a-badge>
    </div>
    <a-menu slot="overlay">
      <a-menu-item>
        <strong>Hello, {{ profile.name }}</strong>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <div><strong class="mr-1">Email:</strong> {{ profile.email }}</div>
        <div><strong class="mr-1">Phone:</strong> {{ profile.phone }}</div>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item class="d-sm-none d-block">
        <router-link to="/notification">
          <i :class="$style.menuIcon" class="fe fe-bell"></i> Notifikasi
        </router-link>
      </a-menu-item>
<!--      <a-menu-item>-->
<!--        <router-link to="/settings/my/profile">-->
<!--          <i :class="$style.menuIcon" class="fe fe-user"></i> Profile-->
<!--        </router-link>-->
<!--      </a-menu-item>-->
      <a-menu-item>
        <router-link to="/settings/my/profile/change-password">
          <i :class="$style.menuIcon" class="fe fe-settings"></i> Ubah Password
        </router-link>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <a href="javascript: void(0);" @click="doLogout">
          <i :class="$style.menuIcon" class="fe fe-log-out"></i> Logout
        </a>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>

import {mapGetters, mapMutations} from "vuex";
import { getNotification} from "@/api/notification.api";

export default {
  data: function() {
    return {
      count: 7,
      profile: {},
      image: '',
    };
  },
  computed: {
    ...mapGetters(['user', 'getNotifCount']),
  },
  methods: {
    ...mapMutations(['UPDATE_NOTIF_COUNT']),
    async fetchNotif() {
      let params = { filter: "unread", limit: 2, sort: "desc" };
      try {
        let result = await getNotification(params)
        if(result){
          localStorage.setItem('UPDATE_NOTIFY_COUNT', result.data.paging.total)
        }
        this.UPDATE_NOTIF_COUNT(result.data.paging.total)
      } catch (error) {/**/}
    },
    doLogout() {
      localStorage.clear();
      this.$router.push("/auth/login");
      location.reload();
    },
    
  },
  mounted() {
    this.profile =  this.user;
    this.image = this.user.avatar.url;
    this.fetchNotif();
  }
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
