import Vue from "vue";
import Router from "vue-router";
import AuthLayout from "@/layouts/Auth";
import AppLayout from "@/layouts/App";

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "dashboard",
      component: AppLayout,
      meta: { authRequired: true, completeProfile:true, hidden: true },
      children: [
        // Home Dashboard
        {
          path: "/dashboard",
          meta: {
            title: "Dashboard"
          },
          component: () => import("./views/dashboard")
        },

        {
          path: "/public-content",
          meta: {
            title: "Konten Publik"
          },
          component: () => import("./views/public-content")
        },
        {
          path: "/public-content/category",
          meta: {
            title: "Kategori Konten Publik"
          },
          component: () => import("./views/public-content/category")
        },
        {
          path: "/public-content/category/create",
          meta: {
            title: "Tambah Kategori Konten"
          },
          component: () => import("./views/public-content/category/create")
        },
        {
          path: "/public-content/category/update/:id",
          meta: {
            title: "Ubah Kategori Konten"
          },
          component: () => import("./views/public-content/category/update"),
          props: true
        },
        {
          path: "/public-content/create",
          meta: {
            title: "Tambah Konten"
          },
          component: () => import("./views/public-content/create")
        },
        {
          path: "/public-content/update/:id",
          meta: {
            title: "Ubah Konten"
          },
          component: () => import("./views/public-content/update"),
          props: true
        },
        {
          path: "/public-content/report/:id",
          meta: {
            title: "Daftar Pengunjung Konten"
          },
          component: () => import("./views/public-content/report"),
          props: true
        },

        {
          path: "/library-content",
          meta: {
            title: "Konten Perpustakaan"
          },
          component: () => import("./views/library-content")
        },
        {
          path: "/library-content/category",
          meta: {
            title: "Kategori Konten Perpustakaan"
          },
          component: () => import("./views/library-content/category")
        },
        {
          path: "/library-content/category/create",
          meta: {
            title: "Tambah Kategori Konten Perpustakaan"
          },
          component: () => import("./views/library-content/category/create")
        },
        {
          path: "/library-content/category/update/:id",
          meta: {
            title: "Ubah Kategori Konten Perpustakaan"
          },
          component: () => import("./views/library-content/category/update"),
          props: true
        },
        {
          path: "/library-content/create",
          meta: {
            title: "Tambah Konten Perpustakaan"
          },
          component: () => import("./views/library-content/create")
        },
        {
          path: "/library-content/update/:id",
          meta: {
            title: "Ubah Konten Perpustakaan"
          },
          component: () => import("./views/library-content/update"),
          props: true
        },
        {
          path: "/library-content/report/:id",
          meta: {
            title: "Daftar Pengunjung Perpustakaan"
          },
          component: () => import("./views/library-content/report"),
          props: true
        },

        {
          path: "/forum",
          meta: {
            title: "Forum"
          },
          component: () => import("./views/forum")
        },
        {
          path: "/forum/create",
          meta: {
            title: "Tambah Forum"
          },
          component: () => import("./views/forum/create")
        },
        {
          path: "/forum/detail/:id",
          meta: {
            title: "Detail Forum"
          },
          component: () => import("./views/forum/detail"),
          props: true
        },
        {
          path: "/forum/update/:id",
          meta: {
            title: "Update Forum"
          },
          component: () => import("./views/forum/update"),
          props: true
        },
        {
          path: "/forum/comment/:id",
          meta: {
            title: "Daftar Komentar"
          },
          component: () => import("./views/forum/create/comment"),
          props: true
        },
        {
          path: "/forum/category",
          meta: {
            title: "Kategori Topik"
          },
          component: () => import("./views/forum/category")
        },
        {
          path: "/forum/category/create",
          meta: {
            title: "Tambah Kategori Topik"
          },
          component: () => import("./views/forum/category/create")
        },
        {
          path: "/forum/category/update/:id",
          meta: {
            title: "Ubah Kategori Topik"
          },
          component: () => import("./views/forum/category/update"),
          props: true
        },

        {
          path: "/account-management",
          meta: {
            title: "Manajemen Akun"
          },
          component: () => import("./views/account")
        },
        {
          path: "/account-management/view/:id",
          meta: {
            title: "Detail Akun"
          },
          component: () => import("./views/account/view"),
          props: true
        },
        {
          path: "/group",
          meta: {
            title: "Manajemen Group"
          },
          component: () => import("./views/account/group")
        },
        {
          path: "/group/create",
          meta: {
            title: "Tambah Group"
          },
          component: () => import("./views/account/group/create")
        },
        {
          path: "/group/update/:id",
          meta: {
            title: "Ubah Group"
          },
          component: () => import("./views/account/group/update"),
          props: true,
        },
        {
          path: "/event",
          meta: {
            title: "Event"
          },
          component: () => import("./views/event")
        },
        {
          path: "/event/create",
          meta: {
            title: "Tambah Event"
          },
          component: () => import("./views/event/create")
        },
        {
          path: "/event/update/:id",
          meta: {
            title: "Ubah Event"
          },
          component: () => import("./views/event/update"),
          props: true
        },
        {
          path: "/theme",
          meta: {
            title: "Tema"
          },
          component: () => import("./views/theme")
        },
        {
          path: "/onboarding",
          meta: {
            title: "On-Boarding"
          },
          component: () => import("./views/onboarding")
        },
        {
          path: "/settings/my/profile",
          meta: {
            title: "Ubah Profile"
          },
          component: () => import("./views/profile/edit")
        },
        {
          path: "/settings/my/profile/change-password",
          meta: {
            title: "Ubah Password"
          },
          component: () => import("./views/profile/change-password")
        },
        {
          path: "/attendance",
          meta: {
            title: "Daftar Kehadiran"
          },
          component: () => import("./views/attendance")
        },
        {
          path: "/attendance/detail/:id",
          meta: {
            title: "Daftar Kehadiran"
          },
          component: () => import("./views/attendance/detail"),
          props: true
        },
        {
          path: "/notification",
          meta: {
            title: "Notifikasi"
          },
          component: () => import("./views/notification")
        },

        // 404
        {
          path: "/404",
          meta: {
            title: "Error 404"
          },
          component: () => import("./views/system/404")
        }
      ]
    },
    
    // System Pages
    {
      path: "/auth",
      component: AuthLayout,
      redirect: "/auth/login",
      children: [
        {
          path: "/auth/login",
          meta: {
            title: "Login"
          },
          component: () => import("./views/system/login")
        },
        {
          path: "/auth/forgot-password",
          meta: {
            title: "Lupa Password"
          },
          component: () => import("./views/system/forgot-password")
        },
        {
          path: "/auth/forgot-password/code",
          meta: {
            title: "Kode Lupa Password"
          },
          component: () => import("./views/system/code-forgot-password")
        },
        {
          path: "/auth/forgot-password/code/reset-password",
          meta: {
            title: "Reset Password"
          },
          component: () => import("./views/system/reset-password")
        },
      ]
    },
    {
      path: "/page/404",
      meta: {
        title: "Error 404"
      },
      component: () => import("./views/system/404")
    },
    {
      path: "/page/500",
      meta: {
        title: "Error 500"
      },
      component: () => import("./views/system/500")
    },
    // Redirect to 404
    {
      path: "*",
      redirect: "page/404",
      hidden: true
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    const token = localStorage.getItem('token');
    // const expires_at = localStorage.getItem('expires_at');
    // const refresh_token = localStorage.getItem('refresh_token');
    // if(expires_at && refresh_token) {
    //   const expires = new Date(parseInt(expires_at));
    //   const now = new Date();
    //   if(expires < now) {
    //     try {
    //       const response = await refreshToken();
    //       localStorage.setItem("token", response.data.data.access_token);
    //       localStorage.setItem("expires_in", response.data.data.expires_in);
    //       localStorage.setItem("expires_at", now.setSeconds(now.getSeconds() + (response.data.data.expires_in / 4)));
    //       localStorage.setItem("refresh_token", response.data.data.refresh_token);
    //     } catch (err) {
    //       next({
    //         path: "/auth/login",
    //         query: { redirect: to.fullPath }
    //       });
    //     }
    //   }
    // }
    var user = (JSON.parse(localStorage.getItem('USER')))
    if (!token || !user.id) {
      next({
        path: "/auth/login",
        query: { redirect: to.fullPath }
      });
    }else {
      next();
    }
  } else {
    next();
  }
});

export default router;
