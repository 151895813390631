import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
  state: {
    listForum: [],
    filter: {
      searchText: ''
    }
  },
  mutations: {
    UPDATE_DATA_FORUM(state, datas) {
      state.listForum = datas;
    },
    UPDATE_FORUM_SEARCH(state, value) {
      state.filter.searchText = value
    },
    CLEAR_FORUM_FILTER(state) {
      state.filter.searchText = ''
    }
  },
  actions: {},
  getters: {
    listForum: state => state.listForum,
  }
};
