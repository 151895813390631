import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
	state: {
		listPublic: [],
		filter: {
			searchText: '',
			filterCategory: 0,
			filterOnBoarding: { key: 'all' }
		}
	},
	mutations: {
		UPDATE_DATA_PUBLIC(state, data) {
			state.listPublic = data;
		},
		UPDATE_FILTER_SEARCH_PUBLIC(state, value) {
      state.filter.searchText = value
    },
    UPDATE_FILTER_STATUS_PUBLIC(state, value) {
      state.filter.filterCategory = value
    },
    UPDATE_FILTER_OB_PUBLIC(state, value) {
      state.filter.filterOnBoarding = value
    },
		CLEAR_FILTER_PUBLIC(state) {
			state.filter.searchText = ''
      state.filter.filterCategory = 0
      state.filter.filterOnBoarding = { key: 'all' }
		}
	},
	actions: {},
	getters: {
		listPublic: state => state.listPublic,
		filterPublic: state => state.filter
	}
};
