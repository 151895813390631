<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>


export default {
  name: "app",
  computed: {
    nextRoute() {
      return this.$route.query.redirect || "/";
    }
  },
  watch: {
    $route(to) {
      const query = Object.assign({}, to.query);
      this.$store.commit("SETUP_URL_SETTINGS", query);
    }
  }
};
</script>
