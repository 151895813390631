import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
    state: {
        email: '',
        token: ''
    },
    mutations: {
        UPDATE_EMAIL_PASS(state, data) {
            state.email = data;
        },
        UPDATE_TOKEN(state, data) {
            state.token = data;
        },
    },
    actions: {},
    getters: {
        email_confirm: state => state.email,
        token: state => state.token
    }
};
