<template>
  <div>
    <div v-if="loading">
      <a-skeleton />
    </div>
    <div v-else>
      <ul class="list-unstyled" v-if="listData.length > 0">
        <li :class="$style.item" v-for="item in listData" :key="item.id">
          <a
              href="javascript: void(0);"
              :class="$style.itemLink"
              @click="readNotif(item.id, item.data.model)"
          >
            <div class="mr-3">
              <div v-if="item.data.model" style="text-transform: capitalize;">
                {{ item.data.model }}
              </div>
              <div class="text-muted">{{ item.data.message }}</div>
            </div>
            <div :class="$style.itemAction">
              <span />
              <span />
            </div>
          </a>
        </li>
      </ul>
      <ul class="list-unstyled">
        <li class="text-right">
          <router-link :to="{ path: '/notification' }">Lihat semua</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { getNotification, getDetailAndMarkRead } from "@/api/notification.api";
import {mapMutations} from "vuex";

export default {
  name: "NotificationItem",
  data() {
    return {
      listData: [],
      loading: false
    };
  },
  mounted() {
    this.loading = true
    this.fetch();
  },
  methods: {
    ...mapMutations(['UPDATE_NOTIF_COUNT']),
    async fetch() {
      let params = { filter: "unread", limit: 7, sort: "desc" };
      try {
        let result = await getNotification(params);
        this.listData = result.data.results;
        this.UPDATE_NOTIF_COUNT(result.data.paging.total)
      } catch (error) {/**/}
      this.loading=false
    },
    async readNotif(id, _model) {
      try {
        // console.log(id)
        let result = await getDetailAndMarkRead(id);
        if (result) {
          // redirect to all notification
          if(_model == "user"){
            await this.$router.push("/account-management/view/" + result.data.detail.uuid);
          }else {
            if(result.data.detail != null){
              await this.$router.push("/forum/detail/" + result.data.detail.id);
            }else {
              await this.$router.push("/forum");
            }
          }
          await this.fetch()
        }
      } catch (error) {/**/}
    }
  }
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
