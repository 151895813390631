import httpClient from "@/api/httpClient";

const NOTIFICATION = "/api/notifications";

/**
 * Get List
 */
const getNotification = (_params) =>
    httpClient.get(NOTIFICATION, {
        params: _params
    });

const getDetailAndMarkRead = (_idNotif) =>
    httpClient.get(NOTIFICATION + "/" + _idNotif);

const deleteNotification = (_idNotif) =>
    httpClient.delete(NOTIFICATION + "/" + _idNotif);

export { getNotification, getDetailAndMarkRead, deleteNotification };
