export const getMenuData = [
  {
    category: true,
    title: 'Menu  Utama',
  },
  {
    title: 'Dashboard',
    key: 'homedashboard',
    icon: 'fe fe-airplay',
    url: '/dashboard',
  },
  {
    title: 'Konten Publik',
    key: 'publiccontent',
    icon: 'fe fe-book-open',
    count: 47,
    children: [
      {
        title: 'Konten Publik',
        key: 'content',
        url: '/public-content',
        icon: 'fe fe-book',
      },
      {
        title: 'Kategori',
        key: 'categoryContent',
        url: '/public-content/category',
        icon: 'fe fe-file-plus',
      },
    ],
  },
  {
    title: 'Konten Perpustakaan',
    key: 'librarycontent',
    icon: 'fe fe-folder',
    count: 47,
    children: [
      {
        title: 'Perpustakaan',
        key: 'library',
        url: '/library-content',
        icon: 'fe fe-book',
      },
      {
        title: 'Kategori',
        key: 'categoryLibrary',
        url: '/library-content/category',
        icon: 'fe fe-file-plus',
      },
    ],
  },
  {
    title: 'Forum',
    key: 'forum',
    icon: 'fe fe-users',
    count: 47,
    children: [
      {
        title: 'Topik',
        key: 'forumTopic',
        url: '/forum',
        icon: 'fe fe-book',
      },
      {
        title: 'Kategori',
        key: 'categoryForum',
        url: '/forum/category',
        icon: 'fe fe-file-plus',
      },
    ],
  },
  // {
  //   title: 'Kehadiran',
  //   key: 'attendance',
  //   icon: 'fe fe-user-check',
  //   url: '/attendance',
  // },
  {
    title: 'Manajemen',
    key: 'account',
    icon: 'fe fe-monitor',
    count: 47,
    children: [
      {
        title: 'Manajemen Akun',
        key: 'accountManagement',
        url: '/account-management',
        icon: 'fe fe-user-plus',
      },
      {
        title: 'Manajemen Group',
        key: 'group',
        url: '/group',
        icon: 'fe fe-user',
      },
      {
        title: 'Manajemen Event',
        key: 'event',
        url: '/event',
        icon: 'fe fe-calendar',
      },
      {
        title: 'Manajemen Tema',
        key: 'theme',
        url: '/theme',
        icon: 'fe fe-image',
      },
      {
        title: 'On-Boarding',
        key: 'onboarding',
        url: '/onboarding',
        icon: 'fe fe-calendar',
      },
    ],
  },
]
export const getMenuDataAdminUser = [
  {
    category: true,
    title: 'Menu  Utama',
  },
  {
    title: 'Dashboard',
    key: 'homedashboard',
    icon: 'fe fe-airplay',
    url: '/dashboard',
  },
  {
    title: 'Kehadiran',
    key: 'attendance',
    icon: 'fe fe-user-check',
    url: '/attendance',
  },
  {
    title: 'Manajemen',
    key: 'account',
    icon: 'fe fe-monitor',
    count: 47,
    children: [
      {
        title: 'Manajemen Akun',
        key: 'accountManagement',
        url: '/account-management',
        icon: 'fe fe-user-plus',
      },
      {
        title: 'Manajemen Group',
        key: 'group',
        url: '/group',
        icon: 'fe fe-user',
      },
      {
        title: 'Manajemen Event',
        key: 'event',
        url: '/event',
        icon: 'fe fe-calendar',
      },
      {
        title: 'Manajemen Tema',
        key: 'theme',
        url: '/theme',
        icon: 'fe fe-image',
      },
      {
        title: 'On-Boarding',
        key: 'onboarding',
        url: '/onboarding',
        icon: 'fe fe-calendar',
      },
    ],
  },
]
export const getMenuDataAdminKonten = [
  {
    category: true,
    title: 'Menu  Utama',
  },
  {
    title: 'Dashboard',
    key: 'homedashboard',
    icon: 'fe fe-airplay',
    url: '/dashboard',
  },
  {
    title: 'Konten Publik',
    key: 'publiccontent',
    icon: 'fe fe-book-open',
    count: 47,
    children: [
      {
        title: 'Konten Publik',
        key: 'content',
        url: '/public-content',
        icon: 'fe fe-book',
      },
      {
        title: 'Kategori',
        key: 'categoryContent',
        url: '/public-content/category',
        icon: 'fe fe-file-plus',
      },
    ],
  },
  {
    title: 'Konten Perpustakaan',
    key: 'librarycontent',
    icon: 'fe fe-folder',
    count: 47,
    children: [
      {
        title: 'Perpustakaan',
        key: 'library',
        url: '/library-content',
        icon: 'fe fe-book',
      },
      {
        title: 'Kategori',
        key: 'categoryLibrary',
        url: '/library-content/category',
        icon: 'fe fe-file-plus',
      },
    ],
  },
  {
    title: 'Forum',
    key: 'forum',
    icon: 'fe fe-users',
    count: 47,
    children: [
      {
        title: 'Topik',
        key: 'forumTopic',
        url: '/forum',
        icon: 'fe fe-book',
      },
      {
        title: 'Kategori',
        key: 'categoryForum',
        url: '/forum/category',
        icon: 'fe fe-file-plus',
      },
    ],
  },
]
