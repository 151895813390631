<template>
  <div :class="$style.topbar">
    <div class="mr-md-6 mr-auto">
      <air-search />
    </div>

    <div class="mr-4 d-none d-sm-block">
      <air-actions />
    </div>
    <div class>
      <air-user-menu />
    </div>
  </div>
</template>

<script>
import AirSearch from "@/components/layout/TopBar/Search";
import AirActions from "@/components/layout/TopBar/Actions";
import AirUserMenu from "@/components/layout/TopBar/UserMenu";

export default {
  components: {
    AirSearch,
    AirActions,
    AirUserMenu
  }
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
