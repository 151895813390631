import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
	state: {
		listLibrary: [],
		filter: {
			searchText: '',
			filterCategory: 0,
			filterOnBoarding: { key: 'all' }
		}
	},
	mutations: {
		UPDATE_DATA_PUBLIC(state, data) {
			state.listLibrary = data;
		},
		UPDATE_FILTER_SEARCH_LIBRARY(state, value) {
      state.filter.searchText = value
    },
    UPDATE_FILTER_STATUS_LIBRARY(state, value) {
      state.filter.filterCategory = value
    },
    UPDATE_FILTER_OB_LIBRARY(state, value) {
      state.filter.filterOnBoarding = value
    },
		CLEAR_FILTER_LIBRARY(state) {
			state.filter.searchText = ''
      state.filter.filterCategory = 0
      state.filter.filterOnBoarding = { key: 'all' }
		}
	},
	actions: {},
	getters: {
		listLibrary: state => state.listLibrary,
		filterLibrary: (state) => state.filter
	}
};
