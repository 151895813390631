import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
    state: {
        totalNotif: 0,
    },
    mutations: {
        UPDATE_NOTIF_COUNT(state, datas) {
            state.totalNotif = datas;
        },
    },
    actions: {},
    getters: {
        getNotifCount: state => state.totalNotif,
    }
};
