import Vue from "vue";
import VuePageTitle from "vue-page-title";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import NProgress from "vue-nprogress";
import VueLayers from "vuelayers";
import BootstrapVue from "bootstrap-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import * as VueGoogleMaps from 'vue2-google-maps'
import VueNumerals from 'vue-numerals';
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
 

import {
  Avatar,
  TreeSelect,
  Rate,
  Breadcrumb,
  InputNumber,
  Steps,
  Message,
  Upload,
  Button,
  Layout,
  Table,
  Icon,
  Progress,
  Radio,
  Dropdown,
  Menu,
  Modal,
  Carousel,
  Input,
  Calendar,
  Badge,
  Slider,
  Form,
  Tooltip,
  Select,
  Switch,
  Tag,
  Affix,
  Spin,
  Alert,
  Checkbox,
  Tabs,
  Pagination,
  notification,
  Drawer,
  Cascader,
  DatePicker,
  TimePicker,
  Divider,
  Skeleton,
} from "ant-design-vue";

import "./global.scss";

Vue.use(BootstrapVue);
Vue.use(VueLayers);
Vue.use(Avatar);
Vue.use(Divider);
Vue.use(Button);
Vue.use(Rate);
Vue.use(TreeSelect);
Vue.use(Breadcrumb);
Vue.use(Layout);
Vue.use(Table);
Vue.use(Icon);
Vue.use(Progress);
Vue.use(Radio);
Vue.use(Dropdown);
Vue.use(Menu);
Vue.use(Carousel);
Vue.use(Input);
Vue.use(Calendar);
Vue.use(Badge);
Vue.use(Slider);
Vue.use(Form);
Vue.use(Tooltip);
Vue.use(Select);
Vue.use(Tag);
Vue.use(Affix);
Vue.use(Spin);
Vue.use(Alert);
Vue.use(Checkbox);
Vue.use(Tabs);
Vue.use(Pagination);
Vue.use(Upload);
Vue.use(Message);
Vue.use(Steps);
Vue.use(InputNumber);
Vue.use(Drawer);
Vue.use(Switch);
Vue.use(Cascader);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(notification);
Vue.use(Modal);
Vue.use(VueAxios, axios);
Vue.use(VueNumerals);
Vue.use(Skeleton);

Vue.prototype.$notification = notification;
Vue.prototype.$modal = Modal;

Vue.use(NProgress);
Vue.use(VuePageTitle, {
  prefix: "Dolarindo Connect | ",
  router
});
// Google Map
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLEMAP_KEY,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
    v: 3.38
  }
});

Vue.use(VueNumerals, {
  locale: 'id'
});

Vue.config.productionTip = false;
const nprogress = new NProgress({ parent: "body" });
const modalconfirm = Modal.confirm;
localStorage.setItem("showEvent", "1");
new Vue({
  router,
  store,
  nprogress,
  modalconfirm,
  render: h => h(App)
}).$mount("#app");

Vue.use(VueQuillEditor)
