<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft">
    <div :class="$style.dropdown">
      <i class="fe fe-bell" :class="$style.icon"></i>

      <span class="d-none d-xl-inline">Notifikasi </span>
    </div>
    <div slot="overlay">
      <div class="card air__utils__shadow width-350">
        <div class="card-body pb-2">
          <notification />
        </div>
      </div>
    </div>
  </a-dropdown>
</template>

<script>
import Notification from "@/components/widgets/Notification";

export default {
  components: {
    Notification
  },
  data() {
    return {
      count: 10
    };
  }
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
